import { DateTime } from "luxon"

function parseCheckInData(userData){
    const today_iso = DateTime.now().toISO()
    const data = []
    const check_in_uuids = Object.keys(userData).filter((uuid) => userData[uuid].type === 'node--asthma_check_in' && typeof userData[uuid].attributes !== 'undefined')
    if(check_in_uuids.length > 0){
        for (const uuid of check_in_uuids) {
            const entity = userData[uuid]
            const item = {
                time: entity?.attributes?.field_checkin_date_iso ? entity.attributes.field_checkin_date_iso : today_iso,
                symptoms: null,
                level: entity?.attributes?.field_asthma_level ? entity.attributes.field_asthma_level : 0,
                to: ['/asthma-check-in-detail-view', entity],
                hasTakenDailyMed: entity.attributes.field_medication_used == 'yes',
                hasTakenRescueMed: entity?.attributes?.field_inhaler_used == 'yes'
            }
            if(entity?.attributes?.field_symptoms){
                const entity_symptoms_data = JSON.parse(entity.attributes.field_symptoms)
                if(Array.isArray(entity_symptoms_data) && entity_symptoms_data.length > 0){
                    item.symptoms = entity_symptoms_data
                }
            }
            data.push(item)
        }
    }
    return data
}

export default parseCheckInData
