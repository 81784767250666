import React, { useContext, useEffect, useState } from 'react'
import { makeStyles } from "@material-ui/core/styles";
import ReportsWrapper from '../../components/ReportsWrapper'
import { Actions, Section, Typography, Spacer, ReportArchive, CircularProgress} from '../../components/library';
import parseCheckInData from '../../utils/parseCheckInData'
import { ImpactContext } from '../../components/Layout';
import { UserContext } from '@parallelpublicworks/entitysync'

const useStyles = makeStyles((theme) => ({
    actions: {
        display: 'flex'
    }
}))


// checkInData format:
// const data = [
//     {
//         time: yesterday.toISO(),
//         symptoms: [
//             {
//                 time: ISO,
//                 location: 'Playground',
//                 trigger: 'Pollen'
//             },
//             {
//                 time: ISO,
//                 location: 'Playground',
//                 trigger: 'Pollen'
//             },
//             {
//                 time: ISO,
//                 location: 'Mall',
//             },
//         ],
//         level: 2,
//         to: '/components',
//     },
//     {
//         time: '2021-06-14T11:20:50.923-07:00',
//         symptoms: null,
//         level: 4,
//         to: '/components',
//     },
// ]

function index({location}) {
    const classes = useStyles();
    const { userData, isPastWeek, user } = useContext(ImpactContext)
    const userContext = useContext(UserContext)
    const [checkInData, setCheckInData] = useState(null)

    useEffect(() => {
        if(!checkInData && userData){
            setCheckInData(parseCheckInData(userData))
        }
    }, [userData])
    
    if(checkInData === null) return <CircularProgress />

    return (
        <>
            <ReportsWrapper currentTab={0}>
                <>
                    {!isPastWeek && (
                        <>
                            <Spacer amount="3" />
                            <Section bg="glass">
                                <Typography>Great job with checking in! You are now up to date!</Typography>
                                <Actions
                                    dismissTo="/asthma-check-in-form"
                                    dismissLabel="Add Manually"
                                    submitTo="/import-symptoms"
                                    submitLabel="Sync Again"
                                />
                            </Section>
                        </>
                    )}
                    <Spacer amount="3" />
                    <ReportArchive data={checkInData} title="SYMPTOM REPORTS" />
                </>
            </ReportsWrapper>
        </>
    )
}

export default index
